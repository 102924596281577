import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

const routes = require('../../routes');

const Layout = ({ children }) => {
  const hideInstallablePrompt = () => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
    });
  };

  useEffect(() => {
    hideInstallablePrompt();
  });

  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }

      books: allRestApiRoot2TsV2WebsitesSamplePapersHome {
        nodes {
          data {
            home {
              classes_to_books {
                class_name
                subject
                books {
                  name
                  files {
                    name
                    paper_id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const getPapersArray = () => {
    const {
      books: { nodes },
    } = data;
    const {
      data: {
        home: { classes_to_books },
      },
    } = nodes[0];

    const paperArray = [];
    classes_to_books.forEach((node) => {
      // for all books
      node.books.forEach((book) => {
        // for all papers
        book.files.forEach((paper) => {
          paperArray.push({
            paper_id: paper.paper_id,
            name: `${node.class_name} ${node.subject} ${book.name} ${paper.name}`,
            url: `/${routes.download}/${paper.paper_id}`,
          });
        });
      });
    });

    return paperArray;
  };

  return (
    <div className="viewBox mx-auto">
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
      <div className="text-tertiary">
        <main>{children}</main>
      </div>
      <Footer
        siteTitle={data.site.siteMetadata?.title || 'Title'}
        paperArray={getPapersArray()}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
