import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const Header = ({ siteTitle }) => (
  <nav className="flex items-center justify-between flex-wrap py-2  px-4 shadow mb-1 ">
    <Link to="/">
      <div className="flex items-center flex-shrink-0">
        <span className="font-semibold text-l tracking-tight">
          CBSE
          <br />
          Sample Papers
        </span>
      </div>
    </Link>
    <div>
      <a
        href={process.env.GATSBY_LOGO_LINK}
        target="_blank"
        rel="noreferrer"
        className="flex"
      >
        <StaticImage
          src="../../images/hw-logo.png"
          alt="hw-logo"
          placeholder="blurred"
          layout="fixed"
          width={60}
          height={60}
        />
        <span className="font-semibold text-sm tracking-tight ml-2 mt-auto">
          the
          <br />
          homework
          <br />
          app
        </span>
      </a>
    </div>
  </nav>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
