// import { initializeApp } from "firebase/app"
// import { getAnalytics, logEvent } from "firebase/analytics"

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  };

  import('firebase/app').then((firebase) => {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  });
};

const logFirebaseEvent = (event, params) => {
  initFirebase();

  import('firebase/analytics').then((firebase) => {
    const analytics = firebase.getAnalytics();
    firebase.logEvent(analytics, event, params);
  });
};

export default logFirebaseEvent;
export const serverString = 'https://homeworkapp.ai/root2/ts';
