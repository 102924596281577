import { navigate } from 'gatsby';
import React from 'react';
import logFirebaseEvent from '../../utils/firebase';

const CustomLink = ({
  children,
  to,
  firebaseEvent: { eventName, eventParams },
}) => {
  const onClick = () => {
    logFirebaseEvent(eventName, eventParams);
    navigate(to);
  };

  return (
    <div to={to} onClick={onClick} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export default CustomLink;
