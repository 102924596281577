import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import CustomLink from './Link';

const Footer = ({ siteTitle, paperArray }) => (
  <footer className="">
    <div className="bg-primary p-4 text-white">
      <div className="text-white font-extrabold text-4xl">
        CBSE
        <br />
        Sample Papers
      </div>

      <div className="font-semibold text-lg">is brought to you by</div>

      <div className="my-2 align-middle">
        <a
          href={process.env.GATSBY_FOOTER_LINK}
          target="_blank"
          rel="noreferrer"
          className="flex"
        >
          <StaticImage
            src="../../images/hw-logo.png"
            alt="hw-logo"
            placeholder="blurred"
            layout="fixed"
            width={150}
            height={150}
          />
          <span className="font-extrabold text-2xl tracking-tight ml-2 m-auto">
            the
            <br />
            homework
            <br />
            app
          </span>
        </a>
      </div>

      <div className="">
        <a href={process.env.GATSBY_FOOTER_LINK} target="_blank" rel="noreferrer">
          <StaticImage
            src="../../images/play-store.png"
            alt="play-store"
            placeholder="blurred"
            width={300}
          />
        </a>
      </div>

      <div className="mt-4 mb-8">
        {paperArray.map((paper) => (
          <div key={paper.paper_id}>
            <CustomLink
              className="block"
              to={paper.url}
              firebaseEvent={{
                eventName: 'footer_paper_click',
                eventParams: { id: paper.paper_id, value: paper.name },
              }}
            >
              {paper.name}
            </CustomLink>
          </div>
        ))}
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: '',
};

export default Footer;
